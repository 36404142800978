export const keyActionsArea = {
  order: 1,
  name: "Key Actions hub",
  keywords:
    "reputation earnings credit balance money validations pending contributions share sharing help layout",
  subcategory: "Navigating Tower Hunt",
  markdown: `# Key actions hub

  The upper-right corner of Tower Hunt displays a number of important tools and pieces of information to help you get more done. Read on to find out how to make the most of the Key Actions Hub.

  ![Key Actions Hub screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## Available balance

  Your {% inlineAppIcon iconName="dollar" /%}**available balance** tracks {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" %}earning{% /inlineRouterLink %} and {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}spending{% /inlineRouterLink %} activity. A positive balance means you have credits to spend on data. A zero or negative balance means you need to fund credits (or {% inlineRouterLink articleId="subscribe-to-tower-hunt" %}subscribe to Tower Hunt{% /inlineRouterLink %}) to access more data. {% inlineAppIcon iconName="cursor" /%}Left click to visit your {% inlineRouterLink articleId="track-your-earnings" %}Contributions overview{% /inlineRouterLink %} where you can track earning and spending metrics in more detail.

  ## Reputation

  Your {% inlineAppIcon iconName="star" /%}{% inlineRouterLink articleId="understand-reputation" %}**reputation score**{% /inlineRouterLink %} provides an important signal about the quality of your contributions to Tower Hunt's public data layer. {% inlineAppIcon iconName="cursor" /%}Left click to visit your {% inlineRouterLink articleId="track-your-earnings" %}contributions overview tab{% /inlineRouterLink %} where you can track reputation metrics in more detail.

  ## Available validations

  Your {% inlineAppIcon iconName="shieldCheck" /%}{% inlineRouterLink articleId="understand-validations" %}**available validations**{% /inlineRouterLink %} are opportunities to earn money and reputation by contributing to Tower Hunt's public data layer. {% inlineAppIcon iconName="cursor" /%}Left click to visit the {% inlineRouterLink articleId="understand-validations" %}available validations tab{% /inlineRouterLink %} where you can vote on proposed changes that need validation.

  ## Unpublished contributions

  Think of your {% inlineAppIcon iconName="shieldExclamation" /%}{% inlineRouterLink articleId="understand-how-datapoints-become-public" %}**unpublished contributions**{% /inlineRouterLink %} like the "Draft" section of your email &mdash; these are changes that you can publish into the validation pipeline when you're ready. {% inlineAppIcon iconName="cursor" /%}Left click to visit the {% inlineRouterLink articleId="unpublished-contributions" %}unpublished contributions tab{% /inlineRouterLink %} where you can work with datapoints you're trying to make available to the public.

  ## Sharing stage

  Think of the {% inlineAppIcon iconName="share" /%}{% inlineRouterLink articleId="share-datapoints" %}**Sharing Stage**{% /inlineRouterLink %} like a shopping cart that can be filled with {% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %} you want to share. {% inlineAppIcon iconName="cursor" /%}Left click to open the Sharing Stage, where you can choose recipients and finish sharing.

  ## Help center

  This {% inlineAppIcon iconName="questionMark" /%}{% inlineRouterLink %}**Help Center**{% /inlineRouterLink %} is always available!

  ## Layout selector

  The sidebar can be displayed in either a side-by-side or a top-and-bottom orientation. It can also be hidden, making the primary panel take up the entire screen. Use the layout selector to change the orientation.

  ![Layout selector screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ### Keyboard shortcuts
  You can quickly switch among layouts from your keyboard:

  | **Layout** | **Shortcut** |
  |---|---|
  | Side-by-side | {% inlineKeyboardShortcut %}Ctrl{% /inlineKeyboardShortcut %} {% inlineKeyboardShortcut %}Alt{% /inlineKeyboardShortcut %} {% inlineKeyboardShortcut %}S{% /inlineKeyboardShortcut %} |
  | Top-and-bottom | {% inlineKeyboardShortcut %}Ctrl{% /inlineKeyboardShortcut %} {% inlineKeyboardShortcut %}Alt{% /inlineKeyboardShortcut %} {% inlineKeyboardShortcut %}T{% /inlineKeyboardShortcut %} |
  | Full-screen | {% inlineKeyboardShortcut %}Ctrl{% /inlineKeyboardShortcut %} {% inlineKeyboardShortcut %}Alt{% /inlineKeyboardShortcut %} {% inlineKeyboardShortcut %}F{% /inlineKeyboardShortcut %} |`,
};
